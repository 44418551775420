export const ACTIONS_ID = {
  ADD_FILES_AND_FOLDERS: 'file_share_add_files_&_folders',
  CUSTOMIZE_APP: 'file_share_customize_app',
  ADD_UPLOAD_BUTTON: 'file_share_add_upload_button',
};

export const getAppManifestFunc = (
  { appManifestBuilder },
  editorSDK,
  t,
  experiments,
) => {
  return appManifestBuilder
    .configureManagementActions(async (managementActionsBuilder) => {
      managementActionsBuilder.mainActions().addAction({
        title: t('appmanifest.mainAction.manageFiles&Folders'),
        icon: 'file_share_folder_open',
        actionId: ACTIONS_ID.ADD_FILES_AND_FOLDERS,
      });

      managementActionsBuilder.customActions().addAction({
        title: t('appmanifest.customAction.customizeDisplay'),
        icon: 'file_share_adjustments',
        type: 'editorActions',
        actionId: ACTIONS_ID.CUSTOMIZE_APP,
      });
      if (
        experiments &&
        experiments?.enabled('spec.labs-file-share.MyBusinessUploadButton')
      ) {
        managementActionsBuilder.customActions().addAction({
          title: t('appmanifest.customAction.addUploadButton'),
          icon: 'file_share_adjustments',
          type: 'editorActions',
          actionId: ACTIONS_ID.ADD_UPLOAD_BUTTON,
        });
      }
      managementActionsBuilder.learnMoreAction().set({
        id: 'b7605423-9351-4d19-9299-892ff519e75b',
      });
    })
    .build();
};

export const openDashboard = (editorSDK) => {
  editorSDK.editor.openDashboardPanel('', {
    url: 'app/1537b24e-29d1-6d8f-b8e1-d6860f2f70b9',
    closeOtherPanels: false,
  });
};

export const installUploadButton = (sdk) => {
  sdk.document.application
    .add('', {
      appDefinitionId: 'a7c8dd0e-845a-42e4-8dd4-1e5f437fcb73', // upload button appdefid
    })
    .then(() => {
      sdk.editor.closePanel();
    })
    .catch((err) => console.log('installUploadButton', { err }));
};

export const openSettings = async (editorSDK) => {
  const allPages = await editorSDK.pages.data.getAll('');
  const fileSharePage =
    allPages &&
    allPages.find((page) => page.tpaPageId === 'wix_file_share_page');

  if (fileSharePage) {
    const fileSharePageId = fileSharePage.id;
    await editorSDK.document.pages.navigateTo('', {
      pageLink: { type: 'PageLink', pageId: fileSharePageId },
    });
    const fileShareComponentRef = await editorSDK.document.components.getById(
      '',
      { id: fileSharePageId },
    );
    const childrenComponentsFileShare = await editorSDK.components.getChildren(
      '',
      { componentRef: fileShareComponentRef },
    );
    const settingsFileShareId =
      childrenComponentsFileShare &&
      childrenComponentsFileShare.length &&
      childrenComponentsFileShare[0].id;
    settingsFileShareId &&
      (await editorSDK.editor.deeplink.show('', {
        type: 'component',
        params: [settingsFileShareId, 'settings'],
      }));
  }
};
